import { MutationTree } from "vuex";

type MintModuleState = {
  muted: boolean;
};

const mintModuleState: MintModuleState = {
  muted: true,
};

const mintModuleMutations = <MutationTree<MintModuleState>>{
  changeMuted(state, newMuted: boolean) {
    state.muted = newMuted;
  },
};

const mintModule = {
  namespaced: true,

  state: mintModuleState,
  mutations: mintModuleMutations,
};

export { mintModule };
