
import { defineComponent, onMounted, computed } from "vue";
import Navbar from "@/components/Navbar.vue";
import { useStore } from "vuex";
export default defineComponent({
  components: {
    Navbar,
  },
  setup() {
    const store = useStore();

    const muted = computed(() => store.state.mint.muted);

    return {
      muted,
    };
  },
});
