import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2865714a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav__wrapper" }
const _hoisted_2 = ["onClick", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/",
      custom: ""
    }, {
      default: _withCtx(({ navigate }) => [
        _createElementVNode("img", {
          onClick: navigate,
          class: "nav__logo",
          src: require('@/assets/logo.png'),
          alt: "Sin of gambling"
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    })
  ]))
}